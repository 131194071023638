<template>
<v-app-bar
  class="px-md-4"
  color="drawer"
>
  <template #prepend>
    <v-app-bar-nav-icon
      v-if="$vuetify.display.smAndDown"
      @click="drawer = !drawer"
    />
    <nuxt-link to="/" class="logo">
    <svg-logo-text />
    </nuxt-link>
  </template>
  <template v-if="$vuetify.display.mdAndUp">
    <v-btn
      v-for="{ title, to } in mainMenuItems"
      :key="title"
      :to="to"
      class="text-none font-weight-light"
    >
    {{ title }}
    </v-btn>
  </template>
  <v-btn
    class="text-none ml-6"
    text="Sign Up"
    variant="outlined"
    to="/signup"
  />
  <v-btn
    class="text-none mx-6"
    color="primary"
    text="Log In"
    variant="flat"
    to="/leakprofiles/"
  />
  
</v-app-bar>
<v-navigation-drawer
  v-model="drawer"
  color="drawer"
  temporary
  location="left"
  app
>
  <v-list nav>
    <v-list-item
      v-for="(item, i) in mainMenuItems"
      :key="i"
      :to="item.to"
      :active="i === 0"
      link
      :title="item.title"
    />
  </v-list>
</v-navigation-drawer>
</template>

<script setup lang="ts">
const drawer = ref(false)

const mainMenuItems = [
  { title: 'Home', to: '/' },
  { title: 'Pricing', to: '/pricing' },
  { title: 'FAQs', to: '/faqs' },
]

</script>

<style lang="scss" scoped>
.logo {
  color: inherit;
  text-decoration: none;
}
</style>
