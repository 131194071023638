<template>
  <div class="height-fullscreen">
  <nuxt-loading-indicator :height="2" :color="false" />

  <v-layout class="d-flex flex-column height-fullscreen flex-grow-1">
    <landing-page-app-bar />
    <div class="page-content">
      <v-main class="main-container flex-grow-1">
        <slot name="default" />
      </v-main>
      <footer-row class="mt-8"></footer-row>
    </div>
    
  </v-layout>
  </div>
</template>

<style lang="scss" scoped>
@use 'sass:map';
@use "@/assets/vuetify.scss" as vuetify;


.nuxt-loading-indicator {
  background: rgb(var(--v-theme-primary));
}

.page-content {
  flex-grow: 1;
  flex-shrink: 1;
  overflow-y: auto;
  

  &> :deep(.v-main__wrap) {
    height: 100%;
    overflow-y: auto;
  }
}

.main-container {
  min-height: 94vh;
}

</style>
